@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.eot');
    src: local('SuisseIntl Black'), local('SuisseIntl-Black'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.eot');
    src: local('SuisseIntl BlackItalic'), local('SuisseIntl-BlackItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.eot');
    src: local('SuisseIntl Bold'), local('SuisseIntl-Bold'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.eot');
    src: local('SuisseIntl BoldItalic'), local('SuisseIntl-BoldItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.eot');
    src: local('SuisseIntl SemiBold'), local('SuisseIntl-SemiBold'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.eot');
    src: local('SuisseIntl SemiBoldItalic'), local('SuisseIntl-SemiBoldItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.eot');
    src: local('SuisseIntl Medium'), local('SuisseIntl-Medium'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.eot');
    src: local('SuisseIntl MediumItalic'), local('SuisseIntl-MediumItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.eot');
    src: local('SuisseIntl Book'), local('SuisseIntl-Book'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.ttf') format('truetype');
    font-weight: 450;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.eot');
    src: local('SuisseIntl BookItalic'), local('SuisseIntl-BookItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.ttf') format('truetype');
    font-weight: 450;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.eot');
    src: local('SuisseIntl Regular'), local('SuisseIntl-Regular'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.eot');
    src: local('SuisseIntl RegularItalic'), local('SuisseIntl-RegularItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.eot');
    src: local('SuisseIntl Light'), local('SuisseIntl-Light'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.eot');
    src: local('SuisseIntl LightItalic'), local('SuisseIntl-LightItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.eot');
    src: local('SuisseIntl Ultralight'), local('SuisseIntl-Ultralight'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.eot');
    src: local('SuisseIntl UltralightItalic'), local('SuisseIntl-UltralightItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.eot');
    src: local('SuisseIntl Thin'), local('SuisseIntl-Thin'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SuisseIntl';
    src: url('/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.eot');
    src: local('SuisseIntl ThinItalic'), local('SuisseIntl-ThinItalic'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.woff') format('woff'),
        url('/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SangBleuEmpire';
    src: url('/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-Regular.woff');
    src: local('SangBleuEmpire-Regular'),
        url('/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SangBleuEmpire';
    src: url('/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-RegularItalic.woff');
    src: local('SangBleuEmpire-RegularItalic'),
        url('/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
