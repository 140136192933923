@import "fonts";

*,
*:after,
*:before {
    box-sizing: border-box;
}
*:focus {
    outline: none;
    box-shadow: none;
}

html {
    font-size: 16px;
    height: 100%;
}

body {
    height: 100%;
    font-size: 1rem;
    font-family: var(--font);
    color: var(--greyColor);
    overflow-x: hidden;
    background-color: var(--darkColor);
}

#smooth-wrapper {
    z-index: 10;
}

main {
    overflow: hidden;
}
h1 {
    font-family: var(--font);
    font-weight: 700;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}
svg {
    width: 24px;
    height: 24px;

    @include mediaLaptop {
        width: 22px;
        height: 22px;
    }

    @include mediaTablet {
        width: 19px;
        height: 19px;
    }
}
p {
    margin: 0;
}
ol,
ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}
.wrapper {
    overflow-x: hidden;
    position: relative;
}
.d-none {
    display: none!important;
}
.lock {
    overflow: hidden !important;
}
.text ol,
.text ul,
.text li,
.content ol,
.content li,
.content ul {
    padding: initial;
    list-style: initial;
}
.content ul {
    padding: 0 0 0 30px;
}
.content ul li {
    margin: 0 0 16px 0;
}
.content ul ol li:first-child {
    margin-top: 16px;
}
.content ol li {
    margin: 0 0 16px 30px;
    list-style-type: numeric;
}
.flex,
.inline-flex {
    &.jsb {
        justify-content: space-between;
    }

    &.jsa {
        justify-content: space-around;
    }

    &.jfs {
        justify-content: flex-start;
    }

    &.jfe {
        justify-content: flex-end;
    }

    &.jc {
        justify-content: center;
    }

    &.ac {
        align-items: center;
    }

    &.afs {
        align-items: flex-start;
    }

    &.afe {
        align-items: flex-end;
    }

    &.fdc {
        flex-direction: column;
    }
    &.fw {
        flex-wrap: wrap;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    justify-content: stretch;
    min-height: 100%;
}

main {
    flex: 1;
}

.container {
    display: block;
    margin: 0 auto;
    max-width: 1024px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @include mediaTablet {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.input, .textarea {
    width: 100%;
    background-color: transparent;
    font-size: 14px;
    border: 1px solid var(--greyColor);
    line-height: 28px;
    height: 30px;
    color: var(--whiteColor);
    padding: 0 10px;
    margin: 0;

    &.error {
        border-color: #8b0000;
    }
}

.textarea {
    height: 130px;
    line-height: 1.3;
    padding: 10px;
    max-width: 100%;
    resize: none;
}

.checkbox {
    font-size: 14px;

    &.error {
        color: #8b0000;
    }
}