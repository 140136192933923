.input {
    display: block;
    position: relative;
    width: 100%;

    input {
        display: block;
        width: 100%;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        font-weight: 600;
        font-size: 30px;
        letter-spacing: -0.02em;
        line-height: 54px;
        color: var(--darkColor);
        border-bottom: 1px solid rgba(65, 65, 65, 0.2);
        transition: all 0.3s;
    
        @include mediaLaptop {
            font-size: 24px;
        }
    
        @include mediaTablet {
            font-size: 18px;
            line-height: 33px;
        }
    
        @include mediaPhone {
            font-size: 16px;
        }
    
        &:hover {
            border-bottom-color: var(--darkColor);
        }

        &:focus ~ .input__name,
        &:valid ~ .input__name {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.4;
            letter-spacing: -0.02em;
            color: var(--greyColor);
            top: -15px;

            @include mediaLaptop {
                font-size: 14px;
                top: -10px;
            }

            @include mediaTablet {
                font-size: 12px;
                top: -15px;
            }
        }

        &.error {
            border-bottom-color: var(--primaryColor);

            & + .input__name + .input__error {
                display: block;
            }
        }
    }

    &__name {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: 600;
        font-size: 30px;
        letter-spacing: -0.02em;
        line-height: 54px;
        color: var(--darkColor);
        transition: all 0.3s;
        pointer-events: none;

        @include mediaLaptop {
            font-size: 24px;
        }
    
        @include mediaTablet {
            font-size: 18px;
            line-height: 33px;
        }
    
        @include mediaPhone {
            font-size: 16px;
        }
    }

    &__error {
        display: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: -0.02em;
        color: var(--primaryColor);
        margin-top: 15px;

        @include mediaLaptop {
            font-size: 14px;
        }

        @include mediaTablet {
            font-size: 12px;
            margin-top: 10px;
        }
    }
}