// base style
@import "../config";
@import "../mixin";
@import "../animation";
@import "../base";

// ui components style
@import "../../components/ui/Button/Button.scss";
@import "../../components/ui/Input/Input.scss";

// base components style
@import "../../components/Header/Header.scss";
@import "../../components/Footer/Footer.scss";

.main-banner {
    position: relative;
    padding-top: 80px;
    margin-top: 100px;

    @include mediaTablet {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 0;
        margin-top: 50px;
    }
    
    &__item {
        position: absolute;
        z-index: 5;

        @include mediaTablet {
            position: static;
            text-align: center;
            padding: 10px;
            width: 50%;

            img {
                max-height: 35px;
                width: auto;
            }
        }

        &:nth-child(1) {
            top: 1%;
            left: 3%;
        }

        &:nth-child(2) {
            top: 0;
            left: 32%;
        }

        &:nth-child(3) {
            top: 6%;
            left: 55%;
        }

        &:nth-child(4) {
            top: 21%;
            left: 70%;
        }

        &:nth-child(5) {
            top: 38%;
            left: 78%;
        }

        &:nth-child(6) {
            top: 58%;
            left: 76%;
        }

        &:nth-child(7) {
            top: 69%;
            left: 70%;
        }

        &:nth-child(8) {
            top: 79%;
            left: 58%;
        }
    }

    &__pic {
        width: 100%;
        display: block;

        @include mediaTablet {
            display: none;
        }
    }

    &__img {
        width: 100%;
        height: auto;
        max-width: 100%;
    }
}

.page-content {
    padding-top: 40px;
}

.page-editor {
    line-height: 1.3;

    p {
        margin-bottom: 20px;
        text-indent: 20px;
    }
}

.page-banner {
    img {
        width: 100%;
        height: auto;
    }
}

.partners-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 100px;

    @include mediaTablet {
        padding-top: 40px;
    }

    .partner-block {
        display: block;
        width: calc(50% - 40px);
        text-align: center;
        margin: 20px;

        @include mediaTablet {
            width: 100%;
            margin: 10px 0;

            img {
                max-height: 45px;
            }
        }

        &:nth-child(3n+3) {
            width: calc(100% - 40px);

            @include mediaTablet {
                width: 100%;
            }
        }
    }
}

.partner-block {
    &__link {
        display: inline-block;
    }
}

.page-contacts {
    padding-top: 80px;
    display: flex;
    align-items: flex-start;

    @include mediaTablet {
        padding-top: 40px;
        flex-direction: column;
    }

    &__col {
        width: 50%;

        @include mediaTablet {
            width: 100%;
        }

        .contact-block {
            margin-bottom: 30px;
        }
    }
}

.contact-block {
    display: flex;
    align-items: flex-start;

    &__ico {
        max-width: 30px;
        height: auto;
        margin-right: 20px;
        margin-top: -2px;
    }

    &__text {
        flex: 1;
        line-height: 1.3;

        a {
            color: var(--greyColor);
        }
    }
}

.form-contacts {
    label {
        display: block;
        width: 100%;
        margin-bottom: 5px;
    }

    .btn-send {
        float: right;
    }
}

.btn-send {
    background-color: var(--darkColor);
    color: var(--greyColor);
    cursor: pointer;
    font-weight: 600;
    border: 1px solid var(--greyColor);
    padding: 5px 10px;
}

.page-press {
    padding-top: 80px;

    @include mediaTablet {
        padding-top: 40px;
    }

    .grid {
        .press-block {
            width: calc(33.333% - 20px);
            margin: 0 10px 20px 10px;

            @include mediaTablet {
                width: calc(50% - 20px);
            }
        }
    }

    &-list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .press-block {
            width: 33.333%;
            height: 235px;
            transition: opacity 0.3s;

            @include mediaTablet {
                width: 50%;
            }

            &:hover, &:focus {
                opacity: 0.5;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.press-block {
    font-size: 0;

    img {
        width: 100%;
        height: auto;
    }
}

.page-category {
    padding-top: 80px;

    @include mediaTablet {
        padding-top: 40px;
    }

    &__text {
        margin-left: auto;
        max-width: 430px;
        font-size: 14px;
        line-height: 1.3;

        @include mediaTablet {
            max-width: 100%;
        }
    }

    &__slider {
        margin-top: 20px;

        &:hover {
            .swiper-button-next, .swiper-button-prev {
                opacity: 1;

                &.swiper-button-disabled {
                    opacity: 0.3;
                }
            }
        }

        a {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 0;
        }

        .swiper-button-next, .swiper-button-prev {
            color: var(--whiteColor);
            z-index: 5;
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.3s 0s;
            will-change: opacity;
            pointer-events: all;

            @include mediaTablet {
                opacity: 1;

                &:after {
                    font-size: 30px;
                }

                &.swiper-button-disabled {
                    pointer-events: all;
                    opacity: 0.3;
                }    
            }

            &.swiper-button-disabled {
                pointer-events: all;
            }

            &.swiper-button-disabled {
                &:hover {
                    opacity: 0.3;
                }
            }
        }

        .swiper-slide {
            text-align: center;
        }
    }
}

.page-category-single {
    padding-top: 20px;

    &__list-cat {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 40px;

        @include mediaTablet {
            margin-top: 20px;
            text-align: center;
            justify-content: center;
        }

        li {
            margin-right: 20px;

            @include mediaTablet {
                margin-right: 10px;
            }

            a {
                display: inline-block;
                padding: 5px 20px;
                color: var(--whiteColor);
                text-decoration: none;
                transition: opacity 0.3s;
                font-size: 15px;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }

    &__slider {
        &:hover {
            .page-category {
                &__btn-slider {
                    opacity: 1;
                    will-change: opacity;
                }
            }
        }

        .swiper-button-next, .swiper-button-prev {
            color: var(--greyColor);
            z-index: 5;
            cursor: pointer;
            will-change: transform;

            @include mediaTablet {
                &:after {
                    font-size: 30px;
                }

                &.swiper-button-disabled {
                    pointer-events: all;
                    opacity: 0.3;
                }    
            }

            &.swiper-button-disabled {
                pointer-events: all;
            }
        }

        .swiper-slide {
            text-align: center;
        }
    }

    &__content {
        border-top: 1px solid var(--greyColor);
        padding-top: 10px;
    }
}

.media-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 60px;

    @include mediaTablet {
        padding-top: 40px;
    }

    .media-block {
        display: block;
        width: calc(33.333% - 30px);
        text-align: center;
        margin: 15px;

        @include mediaTablet {
            width: calc(50% - 30px);
        }

        @include mediaPhone {
            width: 100%;
            margin: 10px;
        }

        &:first-child {
            width: calc(100% - 30px);

            img {
                width: 100%;
                height: 580px;
                object-fit: cover;
                max-width: 100%;
            }

            @include mediaTablet {
                width: 100%;

                img {
                    height: 420px;
                }
            }

            @include mediaPhone {
                img {
                    height: 220px;
                }
            }
        }
    }
}

.media-block {
    &__link {
        position: relative;
        display: block;

        &:hover {
            &::before {
                opacity: 0.7;
            }
        }

        &::before {
            content: "";
            display: block;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: var(--greyColor);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.3s;
        }

        &::after {
            content: "";
            display: block;
            left: 20px; 
            border: 15px solid transparent; 
            border-left: 20px solid var(--whiteColor);
            position: absolute;
            left: calc(50% + 10px);
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        max-width: 100%;
    }
}

.page-events {
    padding-top: 80px;

    @include mediaTablet {
        padding-top: 40px;
    }

    .grid {
        .events-block {
            width: calc(50% - 20px);
            margin: 0 10px 20px 10px;

            @include mediaPhone {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
    }

    &-list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .events-block {
            width: 50%;
            transition: opacity 0.3s;

            @include mediaPhone {
                width: 100%;
            }

            &:hover, &:focus {
                opacity: 0.5;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__slider {
        max-width: 600px;
        width: 100%;
        font-size: 0;

        img {
            width: 100%;
            height: 580px;
            object-fit: cover;
            max-width: 100%;
            //object-position: top;
        }

        @include mediaTablet {
            img {
                height: 460px;
            }
        }

        .swiper-button-next, 
        .swiper-button-prev {
            color: var(--greyColor);
        }

        &-nav {
            max-width: 600px;
            width: 100%;
            font-size: 0;
            margin-top: 10px;

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                max-width: 100%;
                //object-position: top;
            }
    
            @include mediaTablet {
                img {
                    height: 80px;
                }
            }

            .swiper-slide {
                border: 1px solid transparent;

                &-thumb-active {
                    border-color: var(--greyColor);
                }
            }
        }
    }
}

.events-block {
    background-color: var(--whiteColor);
    color: var(--darkColor);

    a {
        display: block;
        color: var(--darkColor);
    }

    img {
        width: 100%;
        height: auto;
    }

    &__title {
        display: block;
        font-size: 18px;
        padding: 20px;
        padding-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__desc {
        display: block;
        font-size: 14px;
        padding: 20px;
        padding-top: 0;
        line-height: 1.4;
    }
}