.header {
    position: relative;
    padding-top: 10px;

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__lang {
        position: absolute;
        right: -84px;
        top: 16px;
        
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include mediaTablet {
            position: static;
            width: 100%;
            text-align: center;
            justify-content: center;
        }

        li {
            &:not(:first-child) {
                margin-left: 15px;

                @include mediaTablet {
                    margin-left: 40px; 
                }
            }

            a {
                color: var(--greyColor);
                text-transform: uppercase;
                font-weight: 500;

                @include mediaTablet {
                    font-size: 20px;
                }

                &.active {
                    color: var(--whiteColor);
                }
            }
        }
    }

    &__btn-menu {
        display: none;
        position: relative;
        width: 50px;
        height: 50px;
        border: 1px solid var(--greyColor);
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        @include mediaTablet {
            display: flex;
        }

        span {
            display: block;
            height: 3px;
            width: 28px;
            background-color: var(--greyColor);
            transition: width 0.3s;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            height: 3px;
            width: 28px;
            background-color: var(--greyColor);
            position: absolute;
            transition: transform 0.3s;
        }

        &::after {
            bottom: 13px;
        }

        &::before {
            top: 14px;
        }

        &.active {
            span {
                width: 0;
            } 

            &::before {
                transform: rotate(45deg) translate(6px, 7px);
            }

            &::after {
                transform: rotate(-45deg) translate(6px, -7px);
            }
        }
    }

    &__wrap-logo {
        text-align: right;
        padding-top: 5px;

        @include mediaTablet {
            padding: 0;
            padding-top: 10px;
            text-align: center;
        }

        a {
            display: inline-block;
        }

        .logo {
            display: block;
            font-size: 0;
        }
    }
}

.main-menu {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--whiteColor);
    border-bottom: 1px solid var(--whiteColor);
    font-size: 14px;

    @include mediaTablet {
        display: none;
        position: absolute;
        right: 15px;
        top: 95px;
        width: 90%;
        background-color: black;
        z-index: 10;
        border: 1px solid var(--greyColor);
        font-size: 16px;

        &.active {
            display: block;
        }
    }

    li {
        display: block;
        width: 100%;
        text-align: center;

        @include mediaTablet {
            text-align: left;

            &:not(:last-child) {
                border-bottom: 1px solid var(--greyColor);
            }
        }

        &.active {
            a {
                color: var(--greyColor);
            }
        }

        a {
            display: block;
            padding: 5px 0;
            color: var(--whiteColor);
            text-transform: uppercase;

            @include mediaTablet {
                padding: 15px;
            }
        }
    }
}