.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    line-height: 56px;
    height: 58px;
    border-radius: 58px;
    border: 1px solid var(--primaryColor);
    color: var(--whiteColor);
    background-color: var(--primaryColor);
    cursor: pointer;
    padding: 0 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8vw;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all 0.3s;

    @include media(2280px) {
        font-size: 16px;
    }

    @include mediaLaptop {
        line-height: 52px;
        height: 54px;
        border-radius: 54px;
        padding: 0 40px;
        font-size: 14px;
    }

    @include mediaTablet {
        line-height: 45px;
        height: 47px;
        border-radius: 47px;
        padding: 0 30px;
        font-size: 12px;
    }

    svg {
        width: 24px;
        min-width: 24px;
        height: 24px;
        fill: var(--whiteColor);
        margin-left: 10px;
        transition: all 0.3s;

        @include mediaLaptop {
            width: 22px;
            min-width: 22px;
            height: 22px;
        }

        @include mediaTablet {
            width: 19px;
            min-width: 19px;
            height: 19px;
        }
    }

    &--ico-left {
        flex-direction: row-reverse;

        svg {
            margin-left: 0;
            margin-right: 10px;
        }
    }
    
    &--secondary {
        border: 1px solid var(--secondaryColor);
        color: var(--whiteColor);
        background-color: var(--secondaryColor);
    }

    &--dark {
        border: 1px solid var(--darkColor);
        color: var(--whiteColor);
        background-color: var(--darkColor);

        &.btn--line {
            color: var(--darkColor);
            background-color: transparent;

            svg {
                fill: var(--darkColor);

                &.ico-line {
                    fill: none;
                    stroke-width: 2px;
                    stroke: var(--darkColor);
                }
            }
            
            &:hover {
                background-color: var(--darkColor);
                color: var(--whiteColor);

                svg {
                    stroke: var(--whiteColor);
                }
            }
        }
    }

    &--light {
        border: 1px solid var(--whiteColor);
        color: var(--darkColor);
        background-color: var(--whiteColor);

        svg {
            fill: var(--darkColor);
        }

        &.btn--line {
            color: var(--whiteColor);
            background-color: transparent;

            svg {
                fill: var(--whiteColor);
            }
        }
    }

    svg {
        &.ico-line {
            fill: none;
            stroke-width: 2px;
            stroke: var(--whiteColor);
        }
    }

    &--anim {
        position: relative;
        overflow: hidden;

        &:before {
            transition: 0.3s;
        }

        &:hover {
            &:before {
                transition: 0.7s;
            }
        }

        .wave {
            background: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            transform: scale(0);
            animation: wave 0.5s linear;
            pointer-events: none;
            position: absolute;
        }
    }
}

.btn-text {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding: 0;
    color: var(--secondaryColor);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    min-height: 26px;
    letter-spacing: -0.02em;
    cursor: pointer;

    @include mediaLaptop {
        font-size: 14px;
    }

    &:hover {
        svg {
            transform: translateX(5px);
        }
    }

    svg {
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-top: -2px;
        fill: none;
        stroke-width: 2px;
        stroke: var(--secondaryColor);
        transition: transform 0.3s;
        will-change: transform;

        @include mediaLaptop {
            width: 22px;
            height: 22px;
        }
    }

    &--ico-left {
        flex-direction: row-reverse;

        svg {
            margin-left: 0;
            margin-right: 10px;
        }

        &:hover {
            svg {
                transform: translateX(-5px);
            }
        }
    }
}

.btn-ico {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    min-width: 58px;
    height: 58px;
    font-size: 0;
    border: 1px solid var(--darkColor);
    border-radius: 50%;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    transition: background-color 0.3s;
    cursor: pointer;
    
    @include mediaLaptop {
        width: 54px;
        min-width: 54px;
        height: 54px;
    }
    
    @include mediaTablet {
        min-width: 47px;
        height: 47px;
        width: 47px;
    }

    &:hover {
        background-color: var(--darkColor);

        svg {
            fill: var(--whiteColor);

            &.ico-line {
                fill: none;
                stroke: var(--whiteColor);
            }
        }
    }

    svg {
        fill: var(--darkColor);
        transition: all 0.3s;

        &.ico-line {
            fill: none;
            stroke: var(--darkColor);
            stroke-width: 2px;
        }
    }

    &--light {
        border: 1px solid var(--whiteColor);

        &:hover {
            background-color: var(--whiteColor);
    
            svg {
                fill: var(--darkColor);
    
                &.ico-line {
                    fill: none;
                    stroke: var(--darkColor);
                }
            }
        }

        svg {
            fill: var(--whiteColor);

            &.ico-line {
                stroke: var(--whiteColor);
            }
        }

        &.btn--line {
            color: var(--whiteColor);
            background-color: transparent;

            svg {
                fill: var(--whiteColor);
            }
        }
    }

    &:disabled {
        border-color: var(--greyColor);
        cursor: default;

        &:hover {
            border-color: var(--greyColor);
            background-color: transparent;

            svg {
                fill: var(--greyColor);
    
                &.ico-line {
                    stroke: var(--greyColor);
                }
            }
        }

        svg {
            fill: var(--greyColor);

            &.ico-line {
                fill: none;
                stroke: var(--greyColor);
            }
        }
    }
}