._anim {
    display: block;
    overflow: hidden;

    span,
    p,
    a {
        display: block;
        transform: translateY(200%);
        transition-property: transform;
        transition-duration: 1.5s;
        //transition-timing-function: cubic-bezier(.25,.74,.22,.99);

        &.is-inview {
            transform: translateY(0);
        }
    }

    &.animate {
        text-shadow: none;

        span,
        p,
        a {
            transform: translateY(0);
        }
    }
}

.t-delay {
    &--0_5 {
        transition-delay: 0.5s !important;
    }

    &--1 {
        transition-delay: 1s !important;
    }

    &--1_5 {
        transition-delay: 1.5s !important;
    }

    &--2 {
        transition-delay: 2s !important;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wave {
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes openMenu {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}