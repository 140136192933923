:root {
  --darkColor: black;
  --whiteColor: #fff;
  --greyColor: rgb(102,102,102);
  --font: Arial, Helvetica, sans-serif;
}

._anim {
  display: block;
  overflow: hidden;
}
._anim span,
._anim p,
._anim a {
  display: block;
  transform: translateY(200%);
  transition-property: transform;
  transition-duration: 1.5s;
}
._anim span.is-inview,
._anim p.is-inview,
._anim a.is-inview {
  transform: translateY(0);
}
._anim.animate {
  text-shadow: none;
}
._anim.animate span,
._anim.animate p,
._anim.animate a {
  transform: translateY(0);
}

.t-delay--0_5 {
  transition-delay: 0.5s !important;
}
.t-delay--1 {
  transition-delay: 1s !important;
}
.t-delay--1_5 {
  transition-delay: 1.5s !important;
}
.t-delay--2 {
  transition-delay: 2s !important;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes wave {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes openMenu {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.eot");
  src: local("SuisseIntl Black"), local("SuisseIntl-Black"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.eot");
  src: local("SuisseIntl BlackItalic"), local("SuisseIntl-BlackItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BlackItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.eot");
  src: local("SuisseIntl Bold"), local("SuisseIntl-Bold"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.eot");
  src: local("SuisseIntl BoldItalic"), local("SuisseIntl-BoldItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.eot");
  src: local("SuisseIntl SemiBold"), local("SuisseIntl-SemiBold"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.eot");
  src: local("SuisseIntl SemiBoldItalic"), local("SuisseIntl-SemiBoldItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.eot");
  src: local("SuisseIntl Medium"), local("SuisseIntl-Medium"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.eot");
  src: local("SuisseIntl MediumItalic"), local("SuisseIntl-MediumItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.eot");
  src: local("SuisseIntl Book"), local("SuisseIntl-Book"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Book.ttf") format("truetype");
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.eot");
  src: local("SuisseIntl BookItalic"), local("SuisseIntl-BookItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-BookItalic.ttf") format("truetype");
  font-weight: 450;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.eot");
  src: local("SuisseIntl Regular"), local("SuisseIntl-Regular"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.eot");
  src: local("SuisseIntl RegularItalic"), local("SuisseIntl-RegularItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.eot");
  src: local("SuisseIntl Light"), local("SuisseIntl-Light"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.eot");
  src: local("SuisseIntl LightItalic"), local("SuisseIntl-LightItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.eot");
  src: local("SuisseIntl Ultralight"), local("SuisseIntl-Ultralight"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Ultralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.eot");
  src: local("SuisseIntl UltralightItalic"), local("SuisseIntl-UltralightItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-UltralightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.eot");
  src: local("SuisseIntl Thin"), local("SuisseIntl-Thin"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.eot");
  src: local("SuisseIntl ThinItalic"), local("SuisseIntl-ThinItalic"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.woff") format("woff"), url("/assets/templates/fonts/SuisseIntl/SuisseIntl-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SangBleuEmpire";
  src: url("/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-Regular.woff");
  src: local("SangBleuEmpire-Regular"), url("/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SangBleuEmpire";
  src: url("/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-RegularItalic.woff");
  src: local("SangBleuEmpire-RegularItalic"), url("/assets/templates/fonts/SangBleuEmpire/SangBleuEmpire-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}

*:focus {
  outline: none;
  box-shadow: none;
}

html {
  font-size: 16px;
  height: 100%;
}

body {
  height: 100%;
  font-size: 1rem;
  font-family: var(--font);
  color: var(--greyColor);
  overflow-x: hidden;
  background-color: var(--darkColor);
}

#smooth-wrapper {
  z-index: 10;
}

main {
  overflow: hidden;
}

h1 {
  font-family: var(--font);
  font-weight: 700;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 1510px) {
  svg {
    width: 22px;
    height: 22px;
  }
}
@media screen and (max-width: 1024px) {
  svg {
    width: 19px;
    height: 19px;
  }
}

p {
  margin: 0;
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wrapper {
  overflow-x: hidden;
  position: relative;
}

.d-none {
  display: none !important;
}

.lock {
  overflow: hidden !important;
}

.text ol,
.text ul,
.text li,
.content ol,
.content li,
.content ul {
  padding: initial;
  list-style: initial;
}

.content ul {
  padding: 0 0 0 30px;
}

.content ul li {
  margin: 0 0 16px 0;
}

.content ul ol li:first-child {
  margin-top: 16px;
}

.content ol li {
  margin: 0 0 16px 30px;
  list-style-type: numeric;
}

.flex.jsb,
.inline-flex.jsb {
  justify-content: space-between;
}
.flex.jsa,
.inline-flex.jsa {
  justify-content: space-around;
}
.flex.jfs,
.inline-flex.jfs {
  justify-content: flex-start;
}
.flex.jfe,
.inline-flex.jfe {
  justify-content: flex-end;
}
.flex.jc,
.inline-flex.jc {
  justify-content: center;
}
.flex.ac,
.inline-flex.ac {
  align-items: center;
}
.flex.afs,
.inline-flex.afs {
  align-items: flex-start;
}
.flex.afe,
.inline-flex.afe {
  align-items: flex-end;
}
.flex.fdc,
.inline-flex.fdc {
  flex-direction: column;
}
.flex.fw,
.inline-flex.fw {
  flex-wrap: wrap;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  justify-content: stretch;
  min-height: 100%;
}

main {
  flex: 1;
}

.container {
  display: block;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 1024px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.input, .textarea {
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid var(--greyColor);
  line-height: 28px;
  height: 30px;
  color: var(--whiteColor);
  padding: 0 10px;
  margin: 0;
}
.input.error, .textarea.error {
  border-color: #8b0000;
}

.textarea {
  height: 130px;
  line-height: 1.3;
  padding: 10px;
  max-width: 100%;
  resize: none;
}

.checkbox {
  font-size: 14px;
}
.checkbox.error {
  color: #8b0000;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 56px;
  height: 58px;
  border-radius: 58px;
  border: 1px solid var(--primaryColor);
  color: var(--whiteColor);
  background-color: var(--primaryColor);
  cursor: pointer;
  padding: 0 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8vw;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  white-space: nowrap;
  transition: all 0.3s;
}
@media screen and (max-width: 2280px) {
  .btn {
    font-size: 16px;
  }
}
@media screen and (max-width: 1510px) {
  .btn {
    line-height: 52px;
    height: 54px;
    border-radius: 54px;
    padding: 0 40px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .btn {
    line-height: 45px;
    height: 47px;
    border-radius: 47px;
    padding: 0 30px;
    font-size: 12px;
  }
}
.btn svg {
  width: 24px;
  min-width: 24px;
  height: 24px;
  fill: var(--whiteColor);
  margin-left: 10px;
  transition: all 0.3s;
}
@media screen and (max-width: 1510px) {
  .btn svg {
    width: 22px;
    min-width: 22px;
    height: 22px;
  }
}
@media screen and (max-width: 1024px) {
  .btn svg {
    width: 19px;
    min-width: 19px;
    height: 19px;
  }
}
.btn--ico-left {
  flex-direction: row-reverse;
}
.btn--ico-left svg {
  margin-left: 0;
  margin-right: 10px;
}
.btn--secondary {
  border: 1px solid var(--secondaryColor);
  color: var(--whiteColor);
  background-color: var(--secondaryColor);
}
.btn--dark {
  border: 1px solid var(--darkColor);
  color: var(--whiteColor);
  background-color: var(--darkColor);
}
.btn--dark.btn--line {
  color: var(--darkColor);
  background-color: transparent;
}
.btn--dark.btn--line svg {
  fill: var(--darkColor);
}
.btn--dark.btn--line svg.ico-line {
  fill: none;
  stroke-width: 2px;
  stroke: var(--darkColor);
}
.btn--dark.btn--line:hover {
  background-color: var(--darkColor);
  color: var(--whiteColor);
}
.btn--dark.btn--line:hover svg {
  stroke: var(--whiteColor);
}
.btn--light {
  border: 1px solid var(--whiteColor);
  color: var(--darkColor);
  background-color: var(--whiteColor);
}
.btn--light svg {
  fill: var(--darkColor);
}
.btn--light.btn--line {
  color: var(--whiteColor);
  background-color: transparent;
}
.btn--light.btn--line svg {
  fill: var(--whiteColor);
}
.btn svg.ico-line {
  fill: none;
  stroke-width: 2px;
  stroke: var(--whiteColor);
}
.btn--anim {
  position: relative;
  overflow: hidden;
}
.btn--anim:before {
  transition: 0.3s;
}
.btn--anim:hover:before {
  transition: 0.7s;
}
.btn--anim .wave {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transform: scale(0);
  animation: wave 0.5s linear;
  pointer-events: none;
  position: absolute;
}

.btn-text {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  color: var(--secondaryColor);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  min-height: 26px;
  letter-spacing: -0.02em;
  cursor: pointer;
}
@media screen and (max-width: 1510px) {
  .btn-text {
    font-size: 14px;
  }
}
.btn-text:hover svg {
  transform: translateX(5px);
}
.btn-text svg {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-top: -2px;
  fill: none;
  stroke-width: 2px;
  stroke: var(--secondaryColor);
  transition: transform 0.3s;
  will-change: transform;
}
@media screen and (max-width: 1510px) {
  .btn-text svg {
    width: 22px;
    height: 22px;
  }
}
.btn-text--ico-left {
  flex-direction: row-reverse;
}
.btn-text--ico-left svg {
  margin-left: 0;
  margin-right: 10px;
}
.btn-text--ico-left:hover svg {
  transform: translateX(-5px);
}

.btn-ico {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  min-width: 58px;
  height: 58px;
  font-size: 0;
  border: 1px solid var(--darkColor);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background-color: transparent;
  transition: background-color 0.3s;
  cursor: pointer;
}
@media screen and (max-width: 1510px) {
  .btn-ico {
    width: 54px;
    min-width: 54px;
    height: 54px;
  }
}
@media screen and (max-width: 1024px) {
  .btn-ico {
    min-width: 47px;
    height: 47px;
    width: 47px;
  }
}
.btn-ico:hover {
  background-color: var(--darkColor);
}
.btn-ico:hover svg {
  fill: var(--whiteColor);
}
.btn-ico:hover svg.ico-line {
  fill: none;
  stroke: var(--whiteColor);
}
.btn-ico svg {
  fill: var(--darkColor);
  transition: all 0.3s;
}
.btn-ico svg.ico-line {
  fill: none;
  stroke: var(--darkColor);
  stroke-width: 2px;
}
.btn-ico--light {
  border: 1px solid var(--whiteColor);
}
.btn-ico--light:hover {
  background-color: var(--whiteColor);
}
.btn-ico--light:hover svg {
  fill: var(--darkColor);
}
.btn-ico--light:hover svg.ico-line {
  fill: none;
  stroke: var(--darkColor);
}
.btn-ico--light svg {
  fill: var(--whiteColor);
}
.btn-ico--light svg.ico-line {
  stroke: var(--whiteColor);
}
.btn-ico--light.btn--line {
  color: var(--whiteColor);
  background-color: transparent;
}
.btn-ico--light.btn--line svg {
  fill: var(--whiteColor);
}
.btn-ico:disabled {
  border-color: var(--greyColor);
  cursor: default;
}
.btn-ico:disabled:hover {
  border-color: var(--greyColor);
  background-color: transparent;
}
.btn-ico:disabled:hover svg {
  fill: var(--greyColor);
}
.btn-ico:disabled:hover svg.ico-line {
  stroke: var(--greyColor);
}
.btn-ico:disabled svg {
  fill: var(--greyColor);
}
.btn-ico:disabled svg.ico-line {
  fill: none;
  stroke: var(--greyColor);
}

.input {
  display: block;
  position: relative;
  width: 100%;
}
.input input {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: -0.02em;
  line-height: 54px;
  color: var(--darkColor);
  border-bottom: 1px solid rgba(65, 65, 65, 0.2);
  transition: all 0.3s;
}
@media screen and (max-width: 1510px) {
  .input input {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .input input {
    font-size: 18px;
    line-height: 33px;
  }
}
@media screen and (max-width: 510px) {
  .input input {
    font-size: 16px;
  }
}
.input input:hover {
  border-bottom-color: var(--darkColor);
}
.input input:focus ~ .input__name, .input input:valid ~ .input__name {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  color: var(--greyColor);
  top: -15px;
}
@media screen and (max-width: 1510px) {
  .input input:focus ~ .input__name, .input input:valid ~ .input__name {
    font-size: 14px;
    top: -10px;
  }
}
@media screen and (max-width: 1024px) {
  .input input:focus ~ .input__name, .input input:valid ~ .input__name {
    font-size: 12px;
    top: -15px;
  }
}
.input input.error {
  border-bottom-color: var(--primaryColor);
}
.input input.error + .input__name + .input__error {
  display: block;
}
.input__name {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: -0.02em;
  line-height: 54px;
  color: var(--darkColor);
  transition: all 0.3s;
  pointer-events: none;
}
@media screen and (max-width: 1510px) {
  .input__name {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .input__name {
    font-size: 18px;
    line-height: 33px;
  }
}
@media screen and (max-width: 510px) {
  .input__name {
    font-size: 16px;
  }
}
.input__error {
  display: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  color: var(--primaryColor);
  margin-top: 15px;
}
@media screen and (max-width: 1510px) {
  .input__error {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .input__error {
    font-size: 12px;
    margin-top: 10px;
  }
}

.header {
  position: relative;
  padding-top: 10px;
}
.header__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__lang {
  position: absolute;
  right: -84px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .header__lang {
    position: static;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
.header__lang li:not(:first-child) {
  margin-left: 15px;
}
@media screen and (max-width: 1024px) {
  .header__lang li:not(:first-child) {
    margin-left: 40px;
  }
}
.header__lang li a {
  color: var(--greyColor);
  text-transform: uppercase;
  font-weight: 500;
}
@media screen and (max-width: 1024px) {
  .header__lang li a {
    font-size: 20px;
  }
}
.header__lang li a.active {
  color: var(--whiteColor);
}
.header__btn-menu {
  display: none;
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid var(--greyColor);
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
@media screen and (max-width: 1024px) {
  .header__btn-menu {
    display: flex;
  }
}
.header__btn-menu span {
  display: block;
  height: 3px;
  width: 28px;
  background-color: var(--greyColor);
  transition: width 0.3s;
}
.header__btn-menu::before, .header__btn-menu::after {
  content: "";
  display: block;
  height: 3px;
  width: 28px;
  background-color: var(--greyColor);
  position: absolute;
  transition: transform 0.3s;
}
.header__btn-menu::after {
  bottom: 13px;
}
.header__btn-menu::before {
  top: 14px;
}
.header__btn-menu.active span {
  width: 0;
}
.header__btn-menu.active::before {
  transform: rotate(45deg) translate(6px, 7px);
}
.header__btn-menu.active::after {
  transform: rotate(-45deg) translate(6px, -7px);
}
.header__wrap-logo {
  text-align: right;
  padding-top: 5px;
}
@media screen and (max-width: 1024px) {
  .header__wrap-logo {
    padding: 0;
    padding-top: 10px;
    text-align: center;
  }
}
.header__wrap-logo a {
  display: inline-block;
}
.header__wrap-logo .logo {
  display: block;
  font-size: 0;
}

.main-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--whiteColor);
  border-bottom: 1px solid var(--whiteColor);
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  .main-menu {
    display: none;
    position: absolute;
    right: 15px;
    top: 95px;
    width: 90%;
    background-color: black;
    z-index: 10;
    border: 1px solid var(--greyColor);
    font-size: 16px;
  }
  .main-menu.active {
    display: block;
  }
}
.main-menu li {
  display: block;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .main-menu li {
    text-align: left;
  }
  .main-menu li:not(:last-child) {
    border-bottom: 1px solid var(--greyColor);
  }
}
.main-menu li.active a {
  color: var(--greyColor);
}
.main-menu li a {
  display: block;
  padding: 5px 0;
  color: var(--whiteColor);
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .main-menu li a {
    padding: 15px;
  }
}

.footer {
  text-align: center;
  color: var(--greyColor);
  font-size: 12px;
  line-height: 1.4;
  padding: 30px 0;
}

.main-banner {
  position: relative;
  padding-top: 80px;
  margin-top: 100px;
}
@media screen and (max-width: 1024px) {
  .main-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 0;
    margin-top: 50px;
  }
}
.main-banner__item {
  position: absolute;
  z-index: 5;
}
@media screen and (max-width: 1024px) {
  .main-banner__item {
    position: static;
    text-align: center;
    padding: 10px;
    width: 50%;
  }
  .main-banner__item img {
    max-height: 35px;
    width: auto;
  }
}
.main-banner__item:nth-child(1) {
  top: 1%;
  left: 3%;
}
.main-banner__item:nth-child(2) {
  top: 0;
  left: 32%;
}
.main-banner__item:nth-child(3) {
  top: 6%;
  left: 55%;
}
.main-banner__item:nth-child(4) {
  top: 21%;
  left: 70%;
}
.main-banner__item:nth-child(5) {
  top: 38%;
  left: 78%;
}
.main-banner__item:nth-child(6) {
  top: 58%;
  left: 76%;
}
.main-banner__item:nth-child(7) {
  top: 69%;
  left: 70%;
}
.main-banner__item:nth-child(8) {
  top: 79%;
  left: 58%;
}
.main-banner__pic {
  width: 100%;
  display: block;
}
@media screen and (max-width: 1024px) {
  .main-banner__pic {
    display: none;
  }
}
.main-banner__img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.page-content {
  padding-top: 40px;
}

.page-editor {
  line-height: 1.3;
}
.page-editor p {
  margin-bottom: 20px;
  text-indent: 20px;
}

.page-banner img {
  width: 100%;
  height: auto;
}

.partners-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 100px;
}
@media screen and (max-width: 1024px) {
  .partners-list {
    padding-top: 40px;
  }
}
.partners-list .partner-block {
  display: block;
  width: calc(50% - 40px);
  text-align: center;
  margin: 20px;
}
@media screen and (max-width: 1024px) {
  .partners-list .partner-block {
    width: 100%;
    margin: 10px 0;
  }
  .partners-list .partner-block img {
    max-height: 45px;
  }
}
.partners-list .partner-block:nth-child(3n+3) {
  width: calc(100% - 40px);
}
@media screen and (max-width: 1024px) {
  .partners-list .partner-block:nth-child(3n+3) {
    width: 100%;
  }
}

.partner-block__link {
  display: inline-block;
}

.page-contacts {
  padding-top: 80px;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1024px) {
  .page-contacts {
    padding-top: 40px;
    flex-direction: column;
  }
}
.page-contacts__col {
  width: 50%;
}
@media screen and (max-width: 1024px) {
  .page-contacts__col {
    width: 100%;
  }
}
.page-contacts__col .contact-block {
  margin-bottom: 30px;
}

.contact-block {
  display: flex;
  align-items: flex-start;
}
.contact-block__ico {
  max-width: 30px;
  height: auto;
  margin-right: 20px;
  margin-top: -2px;
}
.contact-block__text {
  flex: 1;
  line-height: 1.3;
}
.contact-block__text a {
  color: var(--greyColor);
}

.form-contacts label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
.form-contacts .btn-send {
  float: right;
}

.btn-send {
  background-color: var(--darkColor);
  color: var(--greyColor);
  cursor: pointer;
  font-weight: 600;
  border: 1px solid var(--greyColor);
  padding: 5px 10px;
}

.page-press {
  padding-top: 80px;
}
@media screen and (max-width: 1024px) {
  .page-press {
    padding-top: 40px;
  }
}
.page-press .grid .press-block {
  width: calc(33.333% - 20px);
  margin: 0 10px 20px 10px;
}
@media screen and (max-width: 1024px) {
  .page-press .grid .press-block {
    width: calc(50% - 20px);
  }
}
.page-press-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.page-press-list .press-block {
  width: 33.333%;
  height: 235px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 1024px) {
  .page-press-list .press-block {
    width: 50%;
  }
}
.page-press-list .press-block:hover, .page-press-list .press-block:focus {
  opacity: 0.5;
}
.page-press-list .press-block img {
  height: 100%;
  object-fit: cover;
}

.press-block {
  font-size: 0;
}
.press-block img {
  width: 100%;
  height: auto;
}

.page-category {
  padding-top: 80px;
}
@media screen and (max-width: 1024px) {
  .page-category {
    padding-top: 40px;
  }
}
.page-category__text {
  margin-left: auto;
  max-width: 430px;
  font-size: 14px;
  line-height: 1.3;
}
@media screen and (max-width: 1024px) {
  .page-category__text {
    max-width: 100%;
  }
}
.page-category__slider {
  margin-top: 20px;
}
.page-category__slider:hover .swiper-button-next, .page-category__slider:hover .swiper-button-prev {
  opacity: 1;
}
.page-category__slider:hover .swiper-button-next.swiper-button-disabled, .page-category__slider:hover .swiper-button-prev.swiper-button-disabled {
  opacity: 0.3;
}
.page-category__slider a {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0;
}
.page-category__slider .swiper-button-next, .page-category__slider .swiper-button-prev {
  color: var(--whiteColor);
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s 0s;
  will-change: opacity;
  pointer-events: all;
}
@media screen and (max-width: 1024px) {
  .page-category__slider .swiper-button-next, .page-category__slider .swiper-button-prev {
    opacity: 1;
  }
  .page-category__slider .swiper-button-next:after, .page-category__slider .swiper-button-prev:after {
    font-size: 30px;
  }
  .page-category__slider .swiper-button-next.swiper-button-disabled, .page-category__slider .swiper-button-prev.swiper-button-disabled {
    pointer-events: all;
    opacity: 0.3;
  }
}
.page-category__slider .swiper-button-next.swiper-button-disabled, .page-category__slider .swiper-button-prev.swiper-button-disabled {
  pointer-events: all;
}
.page-category__slider .swiper-button-next.swiper-button-disabled:hover, .page-category__slider .swiper-button-prev.swiper-button-disabled:hover {
  opacity: 0.3;
}
.page-category__slider .swiper-slide {
  text-align: center;
}

.page-category-single {
  padding-top: 20px;
}
.page-category-single__list-cat {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media screen and (max-width: 1024px) {
  .page-category-single__list-cat {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }
}
.page-category-single__list-cat li {
  margin-right: 20px;
}
@media screen and (max-width: 1024px) {
  .page-category-single__list-cat li {
    margin-right: 10px;
  }
}
.page-category-single__list-cat li a {
  display: inline-block;
  padding: 5px 20px;
  color: var(--whiteColor);
  text-decoration: none;
  transition: opacity 0.3s;
  font-size: 15px;
}
.page-category-single__list-cat li a:hover {
  opacity: 0.5;
}
.page-category-single__slider:hover .page-category__btn-slider {
  opacity: 1;
  will-change: opacity;
}
.page-category-single__slider .swiper-button-next, .page-category-single__slider .swiper-button-prev {
  color: var(--greyColor);
  z-index: 5;
  cursor: pointer;
  will-change: transform;
}
@media screen and (max-width: 1024px) {
  .page-category-single__slider .swiper-button-next:after, .page-category-single__slider .swiper-button-prev:after {
    font-size: 30px;
  }
  .page-category-single__slider .swiper-button-next.swiper-button-disabled, .page-category-single__slider .swiper-button-prev.swiper-button-disabled {
    pointer-events: all;
    opacity: 0.3;
  }
}
.page-category-single__slider .swiper-button-next.swiper-button-disabled, .page-category-single__slider .swiper-button-prev.swiper-button-disabled {
  pointer-events: all;
}
.page-category-single__slider .swiper-slide {
  text-align: center;
}
.page-category-single__content {
  border-top: 1px solid var(--greyColor);
  padding-top: 10px;
}

.media-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 60px;
}
@media screen and (max-width: 1024px) {
  .media-list {
    padding-top: 40px;
  }
}
.media-list .media-block {
  display: block;
  width: calc(33.333% - 30px);
  text-align: center;
  margin: 15px;
}
@media screen and (max-width: 1024px) {
  .media-list .media-block {
    width: calc(50% - 30px);
  }
}
@media screen and (max-width: 510px) {
  .media-list .media-block {
    width: 100%;
    margin: 10px;
  }
}
.media-list .media-block:first-child {
  width: calc(100% - 30px);
}
.media-list .media-block:first-child img {
  width: 100%;
  height: 580px;
  object-fit: cover;
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .media-list .media-block:first-child {
    width: 100%;
  }
  .media-list .media-block:first-child img {
    height: 420px;
  }
}
@media screen and (max-width: 510px) {
  .media-list .media-block:first-child img {
    height: 220px;
  }
}

.media-block__link {
  position: relative;
  display: block;
}
.media-block__link:hover::before {
  opacity: 0.7;
}
.media-block__link::before {
  content: "";
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--greyColor);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
}
.media-block__link::after {
  content: "";
  display: block;
  left: 20px;
  border: 15px solid transparent;
  border-left: 20px solid var(--whiteColor);
  position: absolute;
  left: calc(50% + 10px);
  top: 50%;
  transform: translate(-50%, -50%);
}
.media-block img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  max-width: 100%;
}

.page-events {
  padding-top: 80px;
}
@media screen and (max-width: 1024px) {
  .page-events {
    padding-top: 40px;
  }
}
.page-events .grid .events-block {
  width: calc(50% - 20px);
  margin: 0 10px 20px 10px;
}
@media screen and (max-width: 510px) {
  .page-events .grid .events-block {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
.page-events-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.page-events-list .events-block {
  width: 50%;
  transition: opacity 0.3s;
}
@media screen and (max-width: 510px) {
  .page-events-list .events-block {
    width: 100%;
  }
}
.page-events-list .events-block:hover, .page-events-list .events-block:focus {
  opacity: 0.5;
}
.page-events-list .events-block img {
  height: 100%;
  object-fit: cover;
}
.page-events__slider {
  max-width: 600px;
  width: 100%;
  font-size: 0;
}
.page-events__slider img {
  width: 100%;
  height: 580px;
  object-fit: cover;
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .page-events__slider img {
    height: 460px;
  }
}
.page-events__slider .swiper-button-next,
.page-events__slider .swiper-button-prev {
  color: var(--greyColor);
}
.page-events__slider-nav {
  max-width: 600px;
  width: 100%;
  font-size: 0;
  margin-top: 10px;
}
.page-events__slider-nav img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .page-events__slider-nav img {
    height: 80px;
  }
}
.page-events__slider-nav .swiper-slide {
  border: 1px solid transparent;
}
.page-events__slider-nav .swiper-slide-thumb-active {
  border-color: var(--greyColor);
}

.events-block {
  background-color: var(--whiteColor);
  color: var(--darkColor);
}
.events-block a {
  display: block;
  color: var(--darkColor);
}
.events-block img {
  width: 100%;
  height: auto;
}
.events-block__title {
  display: block;
  font-size: 18px;
  padding: 20px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.events-block__desc {
  display: block;
  font-size: 14px;
  padding: 20px;
  padding-top: 0;
  line-height: 1.4;
}