@mixin mediaLaptop {
  @media screen and (max-width: 1510px) {
    @content;
  }
}
@mixin mediaTablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin phoneMedia {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin mediaPhone {
  @media screen and (max-width: 510px) {
    @content;
  }
}
@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin scrollbar($thumbcolor, $rangecolor, $borderradius, $width) {
  scrollbar-color: $thumbcolor $rangecolor;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track {
    background-color: $rangecolor;
    border-radius: $borderradius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumbcolor;
    border-radius: $borderradius;

    &:hover {
      background-color: var(--secondaryColor);
    }
  }
}
